<template>
  <section>
    <div>
      <div class="w-full mx-2 lg:flex bg-white">
        <div>
          <div class="m-2">
            <Dropdown class="w-full" @change="buscarGrupo" v-model="grupoBuscado" :options="listadoGrupos" optionLabel="name" :filter="true" placeholder="Buscar" :showClear="true"></Dropdown>
          </div>
          <div class="lg:w-72 px-2 overflow-y-auto z-0 h-40" style="height: 80vh;">
            <div v-for="(grupo, i) in gruposBuscados.length ? gruposBuscados : listadoGrupos" :key="i">
              <div class="p-4 my-2" :class="grupoSeleccionado ? (grupoSeleccionado === grupo.id ? 'bg-blue-200' : 'bg-gray-200') : (grupoIncomming === grupo.id ? 'bg-blue-200' : 'bg-gray-200')">
                <input class="p-radiobutton" @click="listarPermisosPorGrupo(grupo.id)" :inputId="grupo.id" name="name" :value="grupo.id" v-model="grupoSeleccionado" type="radio">
                <label class="mx-4">{{grupo.name}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-1 lg:px-0 lg:mx-4 mt-4 lg:mt-0">
          <Permisos :nivel="1" :listadoServicios="listadoServicios" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, defineAsyncComponent, computed } from 'vue'
import permisosStore from '../../../../../../../stores/permisos.store'
export default {
  name: 'permisosUsuarios',
  components: {
    Permisos: defineAsyncComponent(() => import('../../components/permisos.vue'))
  },
  setup () {
    // Services
    // References
    const listadoPermisos = ref([])
    const grupoBuscado = ref({})
    const gruposBuscados = ref([])
    // Data
    const autorizaciones = ref([
      {
        nombre: 'Autorizacion total',
        value: 1,
        class: 'text-blue-600'
      },
      {
        nombre: 'Varias autorizaciones',
        value: 2,
        class: 'text-blue-200'
      },
      {
        nombre: 'Falta autorización',
        value: 3,
        class: 'text-gray-500'
      }
    ])
    // Computed
    const dataUser = computed(() => permisosStore.getters._usuario_data)
    const listadoGrupos = computed(() => permisosStore.getters._listadoGrupos)
    const grupoIncomming = computed(() => permisosStore.getters._grupoSelecionado)
    const listadoServicios = computed(() => permisosStore.getters._listadoServicios)
    const grupoSeleccionado = ref(grupoIncomming)
    // Methods
    const listarPermisosPorGrupo = (grupo) => {
      permisosStore.commit('setGrupoSelecionado', grupo)
      permisosStore.dispatch('listarPermisosPorGrupo')
    }
    const buscarGrupo = () => {
      if (grupoBuscado.value) {
        grupoSeleccionado.value = grupoBuscado.value.id
        listarPermisosPorGrupo(grupoBuscado.value.id)
        gruposBuscados.value = listadoGrupos.value.filter(x => x.name === grupoBuscado.value.name)
      } else {
        gruposBuscados.value = []
      }
    }
    return {
      listadoGrupos,
      grupoIncomming,
      listarPermisosPorGrupo,
      listadoPermisos,
      autorizaciones,
      dataUser,
      grupoSeleccionado,
      buscarGrupo,
      grupoBuscado,
      gruposBuscados,
      listadoServicios
    }
  }
}
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
    width: 0.2rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(248, 248, 248);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(207, 207, 207);
  }
  .selectButtonPermissions {
    ::v-deep(.p-checkbox .p-checkbox-box) {
      border-radius: 50%;
    }
    ::v-deep(.p-selectbutton .p-button.p-highlight){
      background: white;
      color: #1C3FAA;
      width: 100%;
      font-weight: 500;
      font-size: 1rem;
      border: 1px solid rgb(28, 63, 170);
      margin-top: 0.5rem;
   }
   ::v-deep(.p-selectbutton .p-button.p-highlight:hover){
      background: white;
      color: #1C3FAA;
      width: 100%;
      font-weight: 500;
      font-size: 1rem;
      border: 1px solid rgb(28, 63, 170);
      margin-top: 0.5rem;
   }
   ::v-deep(.p-selectbutton .p-button){
      background: #F6F6F6;
      color: #7F7E81;
      width: 100%;
      font-size: 1rem;
      margin-top: 0.5rem;
      border: transparent;
    }
   ::v-deep(.p-selectbutton .p-button:hover){
      background: #F6F6F6;
      color: #7F7E81;
      width: 100%;
      font-size: 1rem;
      margin-top: 0.5rem;
      border: transparent;
   }
   ::v-deep(.p-dropdown .p-dropdown-label){
      font-size: 0.4rem !important;
      padding: 0.2rem !important;
   }
  }
</style>
